.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: calc(10px + 2vmin);
  color: white;
  text-transform: uppercase;
}

#pagetitle {
  letter-spacing: 1rem;
  opacity: 0.5;
  margin-bottom: 3em;
}

label {
  width: 100%;
}

#usernameentry {
  background-color: unset;
  border: none;
  border-bottom: 1px rgba(255, 255, 255, 0.25) solid;
  max-width: 90%;
  font-size: 3em;
  color: white;
  text-transform: uppercase;
  box-sizing: content-box;
  transition: border 0.6s;
  font-family: inherit;
  font-weight: inherit;
  letter-spacing: 1rem;
  text-align: center;
}

#usernameentry:focus {
  outline: none;
}
#usernameentry:focus-visible {
  border-bottom: 1px solid goldenrod;
}

#giventitle {
  letter-spacing: 1rem;
  margin: 0.5rem;
  margin-bottom: 3rem;
}

.App-header p {
  margin-bottom: 0;
  opacity: 0.25;
}

.App-link {
  color: #61dafb;
  font-weight: bold;
  text-decoration: none;
}

.App-link:focus, .App-link:hover {
  color: goldenrod;
}

.App-link:visited {
  color: orange;
}

#bossimg {
  position: fixed;
  top: 50px;
  left: 9.5%;
  mix-blend-mode: lighten;
  opacity: 0.0625;
  max-width: 450px;
  width: 80%;
  pointer-events: none;
}